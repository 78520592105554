import React, { useState } from "react";
import Swal from "sweetalert2";
import api from "../Axios/api";

function BookPoojaForm({ onClose, data }) {
  const [date, setDate] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const user = JSON.parse(localStorage.getItem("users"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!date) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a date!",
        confirmButtonText: "OK",
      });
      return;
    }

    Swal.fire({
      icon: "info",
      title: "Confirm Booking",
      text: `You are booking a pooja for ${date}.`,
      confirmButtonText: "Proceed to Payment",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with payment
        try {
          const order = await api.post("/payment/create-payment", {
            amount: data.price,
            currency: "INR",
            user_id: user.id,
            puja: data.name,
            puja_id: data.id,
          });

          const createdAt = order.data.data.created_at;

          const paymentid = order.data.data.id;
          console.log("Payment date set:", createdAt);

          const options = {
            key: "rzp_live_wqQsW2lGC8RXmJ",
            amount: order.data.data.amount,
            currency: "INR",
            name: user.name,
            description: "Temple Session Booking",
            order_id: order.data.data.id,
            handler: async function (response) {
              try {
                const verifyResponse = await api.post(
                  "/payment/verify-payment",
                  {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                  }
                );

                if (verifyResponse.data.success) {
                  // Payment successful, call create API
                  await api.post("/pooja/booking/create", {
                    pujaid: data.id,
                    paymentid: paymentid,
                    amount: data.price,
                    userid: user.id,
                    bookingdate: date,
                    paymentdate: createdAt,
                  });

                  Swal.fire({
                    icon: "success",
                    title: "Booking Confirmed",
                    text: `Pooja successfully booked for ${date}`,
                    confirmButtonText: "OK",
                  });
                  if (onClose) onClose();
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Payment Verification Failed",
                    text: "Please try again.",
                  });
                }
              } catch (error) {
                console.error("Booking API error:", error);
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Booking failed. Please contact support.",
                });
              }
            },
            prefill: {
              email: user.email,
              contact: user.mobile,
            },
            theme: {
              color: "#3399cc",
            },
            method: {
              upi: true,
              qr: true,
            },
          };

          const rzp1 = new window.Razorpay(options);
          rzp1.open();

          rzp1.on("payment.failed", function (response) {
            Swal.fire({
              icon: "error",
              title: "Payment Failed",
              text: `Error: ${response.error.description}`,
            });
          });
        } catch (error) {
          console.error("Payment creation failed:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Payment failed. Please contact support.",
          });
        }
      }
    });
  };

  return (
    <div
      style={{
        maxWidth: "400px",
        margin: "auto",
        textAlign: "center",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
      }}
    >
      <h2>Book Pooja</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "20px" }}>
          <label
            htmlFor="pooja-date"
            style={{
              display: "block",
              marginBottom: "8px",
              fontWeight: "bold",
            }}
          >
            Select Date:
          </label>
          <input
            type="date"
            id="pooja-date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            min={today}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <button
          type="submit"
          style={{
            backgroundColor: "#007BFF",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          Book Now
        </button>
      </form>
    </div>
  );
}

export default BookPoojaForm;

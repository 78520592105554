import React, { useState, useEffect } from 'react';
import '../../styles/onlinepoojabooking.css';
import api from '../Axios/api';

function OnlinePoojaBooking() {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const user = JSON.parse(localStorage.getItem('users'));

    useEffect(() => {
        const fetchBookings = async () => {
            if (!user?.id) {
                setError('User not logged in');
                setLoading(false);
                return;
            }

            try {
                const response = await api.get(`/pooja/getbookingid/${user.id}`);
                if (response.data.success) {
                    setBookings(response.data.data); // Assuming `data` is an array of bookings
                } else {
                    setError('No bookings found');
                }
            } catch (err) {
                setError('Error fetching booking details');
            } finally {
                setLoading(false);
            }
        };

        fetchBookings();
    }, [user?.id]);

    if (loading) {
        return <div className="container">Loading...</div>;
    }

    if (error) {
        return <div className="container error">{error}</div>;
    }

    if (!bookings.length) {
        return <div className="container">No booking details available.</div>;
    }

    return (
        <div className="container">
            <h1 className="booking_heading">Online Pooja Booking Details</h1>
            <div className='card_container'>
                {bookings.map((booking, index) => (
                    <div className="card" key={index}>
                        <div className="detail-row">
                            <span className="label">Pooja Name:</span>
                            <span className="value">{booking.puja_name}</span>
                        </div>
                        <div className="detail-row">
                            <span className="label">Pooja Price:</span>
                            <span className="value">₹{booking.puja_price}</span>
                        </div>
                        <div className="detail-row">
                            <span className="label">Pandit Name:</span>
                            <span className="value">{booking.pandit_name}</span>
                        </div>
                        <div className="detail-row">
                            <span className="label">Pandit Contact:</span>
                            <span className="value">{booking.pandit_mobile}</span>
                        </div>
                        <div className="detail-row">
                            <span className="label">Booking Date:</span>
                            <span className="value">{new Date(booking.booking_date).toLocaleDateString()}</span>
                        </div>
                        <div className="detail-row">
                            <span className="label">Payment Date:</span>
                            <span className="value">{new Date(booking.payment_date).toLocaleDateString()}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OnlinePoojaBooking;

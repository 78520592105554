import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import api from "../Axios/api";
import "./problemPooja.css";
// import Swal from "sweetalert2";

const ProblemPooja = () => {
  const { problem } = useParams();
  const navigate = useNavigate();
  const [ProblemPoojas, setProblemPoojas] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem("users"));

  useEffect(() => {
    const fetchProblemPoojas = async () => {
      try {
        const response = await api.get(`/problem/get/${problem}`);
        setProblemPoojas(response.data.data);
        setLoading(false);
      } catch (error) {
        setError("Error fetching problem details.");
        setLoading(false);
      }
    };

    fetchProblemPoojas();
  }, [problem]);

  const handleImageClick = (id) => {
    navigate(`/problemDetail/${id}`);
  };

  const handleSubmit = async (id) => {
    if (!user) {
      navigate("/login");
    } else {
      navigate(`/problemDetail/${id}`);
    }
  };

  return (
    <>
      <div className="sub_header_problem">
        <div className="container">
          <div className="subheader_inner_problem">
            <div className="subheader_text_problem">
              <h1>Pooja</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>

                <li className="breadcrumb-item active">Pooja</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pooja-category-container">
        {/* <h1 className="pooja-category-title">Pooja Category</h1> */}
        <div className="pooja-category-content">
          {loading ? (
            <p className="loading">Loading...</p>
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : ProblemPoojas ? (
            <div className="cart-container">
              {ProblemPoojas.map((item) => (
                <div className="cart-item" key={item.id}>
                  <div
                    className="cart-item-image"
                    onClick={() => handleImageClick(item.id)}
                  >
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className="cart-item-details">
                    <h3 className="cart-item-title">{item.name}</h3>
                    <p>
                      <strong>Temple Name:</strong> {item.temple_name}
                    </p>
                    <p className="cart-item-price">
                      <strong>Price:</strong> 999₹ upto 3499₹
                    </p>
                  </div>
                  <button
                    onClick={() => handleSubmit(item.id)}
                    className="book-now-btn"
                  >
                    Book Now
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="no-details-message">
              No details found for this problem.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ProblemPooja;

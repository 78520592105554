import React, { useEffect, useRef, useState } from "react";
import logo from "../Components/Assets/PRABHU POOJA LOGO1.png";
import { Link, useNavigate } from "react-router-dom";
import api from "./Axios/api";
import prasadimg from "../Components/Assets/prasadimg.webp";
import userprofileimg from "../Components/Assets/profileimg.png";
import "../styles/navbar.css";

function Navbar() {
  const menuRef = useRef(null);
  const openMenuBtnRef = useRef(null);
  const closeMenuBtnRef = useRef(null);
  const cartRef = useRef(null);
  const profileMenuRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [image, setImage] = useState("");
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [isPrimeMember, setIsPrimeMember] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [product, setProduct] = useState(0);
  const [balance, setBalance] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [prasad, setPrasad] = useState(0);
  const [yoga, setYoga] = useState(0);
  const [cartDropdownOpen, setCartDropdownOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [temple, setTemple] = useState(0);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("users"));

  const checkAuthStatus = () => {
    const token = localStorage.getItem("token");
    const isVerified = localStorage.getItem("isVerified");
    const userJson = localStorage.getItem("users");
    const memberJson = localStorage.getItem("member");

    let user = null;
    let member = null;

    if (userJson) {
      try {
        user = JSON.parse(userJson);
        console.log("User data:", user);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    } else {
      console.log("No user data found in localStorage.");
    }

    if (memberJson) {
      try {
        member = JSON.parse(memberJson);
      } catch (error) {
        console.error("Error parsing member data:", error);
      }
    }

    if (token && isVerified === "true") {
      setIsLoggedIn(true);
      fetchCartItems(user.id);
      if (user) {
        setUserName(user.name);
        setUserMobile(user.mobile);
        setImage(user.image);
        setIsPrimeMember(member);
      }
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkAuthStatus();

    const handleLoginStatusChange = () => {
      checkAuthStatus();
    };

    window.addEventListener("loginStatusChanged", handleLoginStatusChange);

    return () => {
      window.removeEventListener("loginStatusChanged", handleLoginStatusChange);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isVerified");
    localStorage.removeItem("users");
    localStorage.removeItem("member");
    setIsLoggedIn(false);
    setUserName("");
    setUserMobile("");
    setProfileMenuOpen(false);
    navigate("/");
    window.dispatchEvent(new Event("loginStatusChanged"));
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchBalance();
      fetchProduct();
    }
  }, [isLoggedIn]);

  const fetchBalance = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("users"));
      const response = await api.get(`/users/balance/${user.id}`);
      setBalance(response.data.balance);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };

  const fetchProduct = async () => {
    try {
      const response = await api.get(`/orders/getby/${user.id}`);
      setProduct(response.data.data.orderCount);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };

  const fetchCartItems = async (userId) => {
    try {
      const response = await api.get(`/cart/getcart/${userId}`);
      console.log(response.data);
      setCartItems(response.data.data); // Update state with cart items
    } catch (error) {
      console.error("Failed to fetch cart items:", error);
    }
  };

  const getTotalCartItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuOpen &&
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setProfileMenuOpen(false);
      }

      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }

      if (
        cartDropdownOpen &&
        cartRef.current &&
        !cartRef.current.contains(event.target)
      ) {
        setCartDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenuOpen, isMenuOpen, cartDropdownOpen]);

  const handleViewCartClick = () => {
    if (!user) {
      navigate("/login");
      setIsCartModalOpen(false);
    } else {
      navigate("/cart");
      setIsCartModalOpen(false);
    }
  };

  const handleProfileToggle = () => {
    setProfileMenuOpen((prev) => !prev);
    if (isMenuOpen) setMenuOpen(false); // Close main menu if open
    if (cartDropdownOpen) setCartDropdownOpen(false); // Close cart if open
  };

  const handleDropdownToggle = (e) => {
    e.preventDefault();
    setDropdownOpen((prev) => !prev);
    if (profileMenuOpen) setProfileMenuOpen(false); // Close profile menu if open
    if (cartDropdownOpen) setCartDropdownOpen(false); // Close cart if open
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
    if (profileMenuOpen) setProfileMenuOpen(false); // Close profile menu if open
    if (cartDropdownOpen) setCartDropdownOpen(false); // Close cart if open
  };

  const handleEcommerceBookingClick = () => {
    navigate("/ecomerceookingpage");
  };

  const handlePrasadBookingClick = () => {
    navigate("/prasadbookingpage");
  };

  const handleTempleBookingClick = () => {
    navigate("/templebookingpage");
  };

  const handleYogaBookingClick = () => {
    navigate("/yogabookingpage");
  };

  const handlePoojaBookingClick = () => {
    navigate("/poojabooking");
  };

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await api.get(`temple/user/${user.id}`);
        const data = response.data.count;
        // console.log(data);
        if (data) {
          setTemple(data);
        } else {
          setTemple([]);
        }
      } catch (err) {
        console.error("Error fetching bookings:", err);
      }
    };

    fetchBookings();
  }, []);

  useEffect(() => {
    const fetchPrasad = async () => {
      try {
        const response = await api.get(`/user/prasad/getuser/${user.id}`);
        const data = response.data.count;

        if (data) {
          setPrasad(data);
        } else {
          setPrasad([]);
        }
      } catch (err) {
        console.error("Error fetching bookings:", err);
      }
    };

    fetchPrasad();
  }, []);

  useEffect(() => {
    const fetchYoga = async () => {
      try {
        const response = await api.get(`/yoga/getuser/${user.id}`);
        const data = response.data.count;

        if (data) {
          setYoga(data);
        } else {
          setYoga([]);
        }
      } catch (err) {
        console.error("Error fetching bookings:", err);
      }
    };

    fetchYoga();
  }, []);
  const handleBalanceClick = () => {
    navigate('/recharge'); 
  };
  return (
    <div className={`header ${isMenuOpen ? "menu-open" : ""}`}>
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className={`menu ${isMenuOpen ? "open" : ""}`} ref={menuRef}>
          <div className="head">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <button
              type="button"
              className="close-menu-btn"
              ref={closeMenuBtnRef}
              onClick={toggleMenu}
            ></button>
          </div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>

            {/* <li>
              <Link to="/about">About us</Link>
            </li> */}

            <li className={`dropdown ${isDropdownOpen ? "active" : ""}`}>
              <Link to="/onlinepooja" onClick={handleDropdownToggle}>
                Services
              </Link>
              <i
                className="fa-solid fa-chevron-down"
                onClick={handleDropdownToggle}
              ></i>
              <ul className={`sub-menu ${isDropdownOpen ? "active" : ""}`}>
                <li>
                  <Link to="/membership">
                    <span>Membership</span>
                  </Link>
                </li>
                <li>
                  <Link to="/onlinepooja">
                    <span>Online Pooja</span>
                  </Link>
                </li>
                <li>
                  <Link to="/prasaddelivery">
                    <span>Prasad Delivery</span>
                  </Link>
                </li>
                <li>
                  <Link to="/astrology">
                    <span>Astrology</span>
                  </Link>
                </li>
                <li>
                  <Link to="/muhurat">
                    <span>Muhurat</span>
                  </Link>
                </li>
                <li>
                  <Link to="/e-commerce">
                    <span>E-Commerce</span>
                  </Link>
                </li>
                <li>
                  <Link to="/temple">
                    <span>Temple</span>
                  </Link>
                </li>
                <li>
                  <Link to="/pandit">
                    <span>Pandit</span>
                  </Link>
                </li>
                <li>
                  <Link to="/yoga">
                    <span>Yoga</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`dropdown ${isDropdownOpen ? "active" : ""}`}>
              <Link to="/onlinepooja" onClick={handleDropdownToggle}>
                Online Pooja
              </Link>
              <i
                className="fa-solid fa-chevron-down"
                onClick={handleDropdownToggle}
              ></i>
              <ul className={`sub-menu ${isDropdownOpen ? "active" : ""}`}>
                <li>
                  <Link to="/navgrahpooja/4">
                    <span>Navgrah Pooja</span>
                  </Link>
                </li>
                <li>
                  <Link to="/satyanarayankatha/6">
                    <span>Satyanarayan Katha</span>
                  </Link>
                </li>
                <li>
                  <Link to="/vastushantipooja/9">
                    <span>Vastu shanti Pooja</span>
                  </Link>
                </li>
                <li>
                  <Link to="/sundarkandpooja/8">
                    <span>Sundarkand Path</span>
                  </Link>
                </li>
                <li>
                  <Link to="/sidhivinayakpooja/12">
                    <span>Siddhivinayak Pooja</span>
                  </Link>
                </li>
                <li>
                  <Link to="/mahamrityunjayajaap/7">
                    <span>Maha Mrityunjaya Jaap</span>
                  </Link>
                </li>
                <li>
                  <Link to="/rudraabhishekpooja/10">
                    <span>Rudra Abhishek Pooja</span>
                  </Link>
                </li>
                <li>
                  <Link to="/mangaldoshpooja/5">
                    <span>Mangal Dosh Pooja</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`dropdown ${isDropdownOpen ? "active" : ""}`}>
              <Link to="/onlinepooja" onClick={handleDropdownToggle}>
                Product
              </Link>
              <i
                className="fa-solid fa-chevron-down"
                onClick={handleDropdownToggle}
              ></i>
              <ul className={`sub-menu ${isDropdownOpen ? "active" : ""}`}>
                <li>
                  <Link to="/">
                    <span>Special Product</span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <span>Vastu Product</span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <span>Pooja Kits</span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <span>Yantra</span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <span>Rudraksh</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/blogs">Blog</Link>
            </li>
            <li className={`dropdown ${isDropdownOpen ? "active" : ""}`}>
              <Link to="/onlinepooja" onClick={handleDropdownToggle}>
                Brand Info
              </Link>
              <i
                className="fa-solid fa-chevron-down"
                onClick={handleDropdownToggle}
              ></i>
              <ul className={`sub-menu ${isDropdownOpen ? "active" : ""}`}>
                <li>
                  <Link to="/ourteam">
                    <span>Our Team</span>
                  </Link>
                </li>
                <li>
                  <Link to="/about">
                    <span>About Us</span>
                  </Link>
                </li>
                <li>
                  <Link to="/enquiryform">
                    <span>Contact Us</span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <span>Testimonial</span>
                  </Link>
                </li>
                <li>
                  <Link to="/faq">
                    <span>FAQ</span>
                  </Link>
                </li>
                <li>
                  <Link to="/disclaimer">
                    <span>Disclaimer</span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <span>Events</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to="/enquiryform">Contact Us</Link>
            </li> */}
          </ul>
        </div>
        <div className="header-right">
          <button
            type="button"
            className="icon-btn"
            onClick={() => {
              setIsCartModalOpen((prev) => !prev);
              if (profileMenuOpen) setProfileMenuOpen(false);
              if (isMenuOpen) setMenuOpen(false);
            }}
          >
            <i className="fa-solid fa-cart-plus"></i>
            {cartItems.length > 0 && (
              <span className="cart-item-count">{getTotalCartItems()}</span>
            )}
          </button>

          {/* {cartDropdownOpen && (
            <div className="cart-dropdown" ref={cartRef}>
              <h3>Cart Items</h3>
              <ul className="cart-items-list">
                {cartItems.length > 0 ? (
                  cartItems.map((item, index) => (
                    <li key={index} className="cart-dropdown-item">
                      <img
                        src={item.image || prasadimg}
                        alt={item.productName}
                      />
                      <div className="cart-item-details">
                        <p>{item.productName}</p>
                        <p>₹ {item.price}</p>
                        <p>Quantity: {item.quantity}</p>
                      </div>
                    </li>
                  ))
                ) : (
                  <p>No items in cart</p>
                )}
              </ul>
              <button className="view-cart-btn" onClick={handleViewCartClick}>
                View Cart
              </button>
            </div>
          )} */}

          {isCartModalOpen && (
            <div className="cart-modal">
              <div className="cart-modal-content">
                <h3 className="cartmodal-heading">Cart Items</h3>
                <button
                  className="close-cart-modal-btn"
                  onClick={() => setIsCartModalOpen(false)}
                >
                  &times;
                </button>
                <ul className="cart-items-list">
                  {cartItems.length > 0 ? (
                    cartItems.map((item, index) => (
                      <li key={index} className="cart-modal-item">
                        <img
                          src={item.image || prasadimg}
                          alt={item.productName}
                        />
                        <div className="cart-item-details">
                          <p>{item.productName}</p>
                          <p>₹ {item.offerPrice}</p>
                          <p>Quantity: {item.quantity}</p>
                        </div>
                      </li>
                    ))
                  ) : (
                    <p>No items in cart</p>
                  )}
                </ul>
                <button className="view-cart-btn" onClick={handleViewCartClick}>
                  View Cart
                </button>
              </div>
            </div>
          )}

          {isLoggedIn ? (
            <div className="profile-menu" ref={profileMenuRef}>
              <img
                src={image || userprofileimg}
                alt="User"
                className="userimg"
                onClick={handleProfileToggle}
              />
              {profileMenuOpen && (
                <div className="profile-dropdown">
                  <img
                    src={image || userprofileimg}
                    alt="User"
                    className="userimg1"
                    onClick={handleProfileToggle}
                  />
                  <p className="user-name">{userName}</p>
                  <p className="user-mobile">{userMobile}</p>
                  {isPrimeMember && (
                    <p className="user-mobile">You are a prime member💎</p>
                  )}
                  <div className="separator"></div>
                  <p className="user-balance" onClick={handleBalanceClick}>Wallet balance: ₹ {balance}</p>
                  <p
                    className="user-booking"
                    onClick={handleEcommerceBookingClick}
                  >
                    Ecommerce Booking: {product}
                  </p>
                  <p
                    className="user-booking"
                    onClick={handlePrasadBookingClick}
                  >
                    Prasad Booking: {prasad}
                  </p>
                  <p
                    className="user-booking"
                    onClick={handleTempleBookingClick}
                  >
                    Temple Booking: {temple}
                  </p>
                  <p className="user-booking" onClick={handleYogaBookingClick}>
                    Yoga Booking: {yoga}
                  </p>
                  <p className="user-booking" onClick={handlePoojaBookingClick}>
                    Pooja Booking
                  </p>
                  <button onClick={handleLogout} className="logout-button">
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to="/login">
              <button className="login-btn">Login</button>
            </Link>
          )}

          <button
            type="button"
            className="open-menu-btn"
            ref={openMenuBtnRef}
            onClick={toggleMenu}
          >
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

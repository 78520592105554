import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../Axios/api";
import { useNavigate } from "react-router-dom";

function Recharge() {
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);
  const user = JSON.parse(localStorage.getItem("users"));
  console.log("here is the users",user)
  useEffect(() => {
    fetchBalance();
  }, []);

  const fetchBalance = async () => {
    try {
      const response = await api.get(`/users/balance/${user.id}`);
      setBalance(response.data.balance);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };
// console.log("here is the user 1",user)
  const handlePayment = async (price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    try {
    
      const order = await api.post("/payment/create-payment", {
        amount: price,
        currency: "INR",
        user_id: user.id,
        puja: "Astrology",
      });

      const options = {
        key: "rzp_live_wqQsW2lGC8RXmJ", 
        amount: order.data.data.amount,
        currency: "INR",
        name: user.name,
        description: "Astrology",
        order_id: order.data.data.id,
        handler: async function (response) {
          try {
            const verifyResponse = await api.post("/payment/verify-payment", {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });

            alert(`Payment Status: ${verifyResponse.data.message}`);
            fetchBalance();
            navigate("/astrology")
          } catch (error) {
            console.error("Verification failed:", error);
          }
        },
        prefill: {
          email: user.email,
          contact: user.mobile,
        },
        theme: {
          color: "#3399cc",
        },
        method: {
          upi: true,
          qr: true,
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      rzp1.on("payment.failed", function (response) {
        alert(`Error: ${response.error.code} | ${response.error.description}`);
      });
    } catch (error) {
      console.error("Payment failed:", error);
    }
  };

  return (
    <div className="rechareg_section">
      <div className="container">
        <h1 className="recharge_title">Add Money to Wallet</h1>
        <p className="recharge_balace">Available balance:</p>
        <p className="avail_balace">₹ {balance}</p>

        <div className="popular_recharge">
          <h1>Popular Recharge</h1>
        </div>

        <div className="row">
          {['25', '50', '100', '200', '500', '1000'].map((price) => (
            <div className="col-sm-2" key={price}>
              <div className="rupee_wallet" onClick={() => handlePayment(price)}>
                <p>₹ {price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Recharge;

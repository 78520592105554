import React from 'react';
import teamimg from "./Assets/teamimg.jpg";
import "../styles/ourteam.css";

function Ourteam() {
    return (
        <div className="our_team_section">
            <div className="container">
                <h1>Our Team</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="team_box">
                            <img src={teamimg} alt="Team Member" className="team_img" />
                            <h2 className="team_name">Ved Sharma</h2>
                            <p className="team_role">Frontend Developer</p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="team_box">
                            <img src={teamimg} alt="Team Member" className="team_img" />
                            <h2 className="team_name">Ved Sharma</h2>
                            <p className="team_role">Backend Developer</p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="team_box">
                            <img src={teamimg} alt="Team Member" className="team_img" />
                            <h2 className="team_name">Ved Sharma</h2>
                            <p className="team_role">UI/UX Designer</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ourteam;

import React, { useEffect, useState } from "react";
import "../../styles/checkout.css";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../Axios/api";
import Swal from "sweetalert2";

const Checkout = () => {
  const location = useLocation();
  const { productId, quantity, totalPrice, user, booking, images } =
    location.state || {};
  const [paymentMethod, setPaymentMethod] = useState("UPI");
  const [formValues, setFormValues] = useState({
    name: "",
    lastname: "",
    email: "",
    mobile: "",
    address: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setFormValues(user);
    } else {
      navigate("/login");
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const newErrors = {};

    if (!formValues.name) newErrors.name = "First name is required.";
    if (!formValues.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "Email address is invalid.";
    }
    if (!formValues.mobile) {
      newErrors.mobile = "Phone number is required.";
    } else if (formValues.mobile.length !== 10) {
      newErrors.mobile = "Phone number must be 10 digits.";
    }
    if (!formValues.address) newErrors.address = "Address is required.";
    if (!formValues.country) newErrors.country = "Country is required.";
    if (!formValues.city) newErrors.city = "City is required.";
    if (!formValues.state) newErrors.state = "State is required.";
    if (!formValues.postalCode)
      newErrors.postalCode = "Postal Code is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePayment = async () => {
    if (!validate()) return;

    if (paymentMethod === "COD") {
      try {
        await api.post("/orders/create", {
          productId,
          userId: user.id,
          quantity,
          totalPrice,
          booking,
          images,
          paymentMethod: "COD",
        });
        Swal.fire("Order Successful!", "Cash on Delivery selected.", "success");
        navigate("/e-commerce");
      } catch (error) {
        console.error("COD Order creation failed:", error);
        Swal.fire(
          "Error",
          "An error occurred during COD order creation.",
          "error"
        );
      }
    } else {
      try {
        const paymentResponse = await api.post("/payment/create-payment", {
          amount: totalPrice,
          currency: "INR",
          user_id: user.id,
          puja: "order",
        });

        const { id: orderId, amount } = paymentResponse.data.data;

        const options = {
          key:"rzp_live_wqQsW2lGC8RXmJ",
          amount,
          currency: "INR",
          name: "Your Store Name",
          description: "Product Purchase",
          order_id: orderId,
          handler: async function (response) {
            try {
              const verifyResponse = await api.post("/payment/verify-payment", {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              });

              if (verifyResponse.data.success) {
                await api.post("/orders/create", {
                  productId,
                  userId: user.id,
                  quantity,
                  totalPrice,
                  booking,
                  images,
                  paymentMethod: "UPI",
                });
                Swal.fire("Payment and Order Successful!", "", "success");
                navigate("/e-commerce");
              } else {
                Swal.fire("Error", "Payment verification failed.", "error");
              }
            } catch (error) {
              console.error("Verification or order creation failed:", error);
              Swal.fire("Payment or Order creation failed", "", "error");
            }
          },
          prefill: {
            email: user.email,
            contact: user.mobile,
          },
          theme: {
            color: "#3399cc",
          },
          method: {
            upi: true,
            qr: true,
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();

        rzp1.on("payment.failed", function (response) {
          Swal.fire(`Error: ${response.error.description}`, "", "error");
        });
      } catch (error) {
        console.error("Error during payment process:", error);
        Swal.fire("Error", "An error occurred during payment.", "error");
      }
    }
  };

  return (
    <section style={{ marginBottom: "1.5rem" }}>
      <div className="container">
        <div className="checkout">
          <h1>Billing Details</h1>
          <form className="checkout-form">
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="First name"
                className="form-control"
                value={formValues.name}
                onChange={handleChange}
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="lastName"
                placeholder="Last name"
                className="form-control"
                value={formValues.lastname}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="form-control"
                value={formValues.email}
                onChange={handleChange}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
            <div className="form-group">
              <input
                type="tel"
                name="mobile"
                placeholder="Mobile"
                className="form-control"
                value={formValues.mobile}
                onChange={handleChange}
              />
              {errors.mobile && <div className="error">{errors.mobile}</div>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="address"
                placeholder="Address"
                className="form-control"
                value={formValues.address}
                onChange={handleChange}
              />
              {errors.address && <div className="error">{errors.address}</div>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="city"
                placeholder="City"
                className="form-control"
                value={formValues.city}
                onChange={handleChange}
              />
              {errors.city && <div className="error">{errors.city}</div>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="postalCode"
                placeholder="Postal Code"
                className="form-control"
                value={formValues.postalCode}
                onChange={handleChange}
              />
              {errors.postalCode && (
                <div className="error">{errors.postalCode}</div>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="state"
                placeholder="State"
                className="form-control"
                value={formValues.state}
                onChange={handleChange}
              />
              {errors.state && <div className="error">{errors.state}</div>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="country"
                placeholder="Country"
                className="form-control"
                value={formValues.country}
                onChange={handleChange}
              />
              {errors.country && <div className="error">{errors.country}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="paymentMethod">Select Payment Method</label>
              <select
                id="paymentMethod"
                className="form-control"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <option value="" disabled>
                  -- Select Payment Method --
                </option>
                <option value="UPI">UPI</option>
                <option value="COD">Cash on Delivery (COD)</option>
              </select>
            </div>

            <button
              type="button"
              className="primary_btn"
              onClick={handlePayment}
            >
              Book Now
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Checkout;

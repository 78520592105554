import React, { useEffect, useState } from "react";
// import womenimg from "../Assets/astrologer-img.jpg";
import "../../styles/astrologyprofile.css";
import api from "../Axios/api";
import loader from "../Assets/loader.svg";
import { useParams, useNavigate } from "react-router-dom";

const AstrologyProfile = () => {
  const [panditData, setPanditData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requestStatus, setRequestStatus] = useState(null);
  const [error, setError] = useState(null);
  const [balance, setBalance] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("users"));
  const [isMember, setIsMember] = useState(false);
  const member = localStorage.getItem("member");

  useEffect(() => {
    const fetchPanditData = async () => {
      try {
        const response = await api.get(`/pandit/id/${id}`);
        setPanditData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchPanditData();
  }, [id]);

  useEffect(() => {
    const fetchUserBalance = async () => {
      if (user && user.id) {
        try {
          const response = await api.get(`/users/balance/${user.id}`);
          if (response.data.success) {
            setBalance(response.data.balance);
          } else {
            setError(response.data.message || "Failed to fetch balance.");
          }
        } catch (error) {
          setError("Error fetching balance. Please try again.");
          console.error("Error fetching balance:", error);
        }
      }
    };

    fetchUserBalance();
  }, [user]);

  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/\-/g, "")
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  useEffect(() => {
    if (member) {
      try {
        const memberData = JSON.parse(member);
        if (memberData === true) {
          setIsMember(true);
        }
      } catch (error) {
        console.error("Error parsing member data:", error);
      }
    }
  }, [member]);

  const createRequest = async (
    astrologerId,
    type,
    price,
    astrologerPhoneNumber
  ) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    try {
      setModalVisible(true);
      const response = await api.post("/request", {
        user_id: user.id,
        pandit_astrologer_id: astrologerId,
        request_type: type,
        status: "pending",
      });

      const requestId = response.data.requestId;
      pollRequestStatus(
        requestId,
        type,
        astrologerId,
        price,
        astrologerPhoneNumber
      );
    } catch (error) {
      console.error("Failed to create request:", error);
      setError("Failed to create request.");
      setModalVisible(false);
    }
  };

  const pollRequestStatus = (
    requestId,
    type,
    astrologerId,
    price,
    astrologerPhoneNumber
  ) => {
    console.log("in polrequest function", requestId);
    const intervalId = setInterval(async () => {
      try {
        const response = await api.get(`/request/status/${requestId}`);
        console.log("here is the status", response.data.status);
        setRequestStatus(response.data.status);

        if (response.data.status !== "pending") {
          clearInterval(intervalId);
          setModalVisible(false);
          console.log(response.data.status);
          if (response.data.status === "accepted") {
            if (type === "chat") {
              navigate(`/chat/${astrologerId}/${price}/${requestId}`);
            } else if (type === "voice" || type === "video") {
              initiateCall(
                astrologerId,
                type,
                astrologerPhoneNumber,
                requestId
              );
            }
          }
        }
      } catch (error) {
        console.error("Failed to fetch request status:", error);
        clearInterval(intervalId);
        setModalVisible(false);
      }
    }, 5000); // Poll every 5 seconds
  };

  const initiateCall = async (
    astrologerId,
    type,
    astrologerPhoneNumber,
    requestId
  ) => {
    try {
      const response = await api.post("/call/initiate", {
        callerId: user.id,
        receiverId: astrologerId,
        type,
        callerPhoneNumber: user.mobile,
        receiverPhoneNumber: astrologerPhoneNumber,
        request_id: requestId,
      });

      if (response.data.success) {
        alert("Call initiated successfully");
        if (type === "voice") {
          navigate(`/voicecall/${response.data.call.twilioCallSid}`);
        } else if (type === "video") {
          navigate(
            `/videocall/${response.data.call.roomName}/${response.data.call.callerToken}`
          );
        }
      } else {
        alert("Error", response.data.message);
      }
    } catch (error) {
      console.error("Failed to initiate call:", error);
      alert("Failed to initiate call");
    }
  };

  const handleChat = (astrologerId, price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    const b = parseFloat(balance);
    const p = parseFloat(price);

    // Compare and proceed with the logic
    if (b >= p || isMember) {
      createRequest(astrologerId, "chat", price);
    } else {
      alert("Insufficient balance! Please recharge.");
      navigate("/recharge");
    }
  };

  const handleVoiceCall = (astrologerId, mobile, price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    const b = parseFloat(balance);
    const p = parseFloat(price);
    console.log(b >= p, b, p);
    if (b >= p || isMember) {
      createRequest(astrologerId, "voice", price, mobile);
    } else {
      alert("Insufficient balance! Please recharge.");
      navigate("/recharge");
    }
  };

  const handleVideoCall = (astrologerId, price) => {
    if (!user) {
      navigate("/login"); // Redirect to login if not logged in
      return;
    }
    const b = parseFloat(balance);
    const p = parseFloat(price);
    console.log(b >= p, b, p);
    // Compare and proceed with the logic
    if (b >= p || isMember) {
      createRequest(astrologerId, "video", price);
    } else {
      alert("Insufficient balance! Please recharge.");
      navigate("/recharge");
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!panditData) return <div className="no-data">No Pandit Data</div>;

  return (
    <section className="astrology-profile">
      <div className="container">
        <div className="profile-card">
          <div className="profile-header">
            <div className="profile-picture">
              <img
                src={panditData.profileImage}
                alt={panditData.name || "Astrologer"}
                className="profile-img"
              />
            </div>
            <div className="profile-details">
              <h1 className="profile-name">{panditData.name}</h1>
              <div className="profile-profession">{panditData.skills}</div>
              <div className="profile-language">{panditData.language}</div>
              <div className="profile-experience">
                Exp: {panditData.experience} Years
              </div>
              <div className="profile-price">
                <span className="price-amount">
                  ₹ {panditData.price}
                  <span>/min</span>
                </span>
              </div>

              <div className="profile-actions">
                <button
                  className="action-btn chat-btn"
                  onClick={() => handleChat(panditData.id, panditData.price)}
                >
                  Chat
                </button>
                <button
                  className="action-btn call-btn"
                  onClick={() =>
                    handleVoiceCall(
                      panditData.id,
                      panditData.mobile,
                      panditData.price
                    )
                  }
                >
                  Call
                </button>
                <button
                  className="action-btn video-call-btn"
                  onClick={() =>
                    handleVideoCall(panditData.id, panditData.price)
                  }
                >
                  Video Call
                </button>
              </div>
            </div>
          </div>

          <div className="profile-about">
            <h2 className="about-heading">About Me</h2>
            <p className="about-text">
              {panditData.name} is a {panditData.skills} Astrologer in India. He
              will consult you regarding Marriage Consultation, Career and
              Business, Love and Relationship, Wealth and Property, Career
              issues, and much more. The remedies he provides are very easy and
              effective and are proven to be accurate most of the time.
              Moreover, his customers are always satisfied with his solutions
              and remedies. He treats all his customers on a personal level and
              tries to build a relationship with them.
            </p>
          </div>
        </div>
      </div>
      {modalVisible && (
        <div className="loader">
          <div className="spinner">
            <img src={loader} alt="" />
          </div>
        </div>
      )}
    </section>
  );
};

export default AstrologyProfile;

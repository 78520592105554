import React, { useState, useEffect } from 'react';
import api from '../Axios/api';
import '../../styles/prasadbooking.css';

function Prasadbookingpage() {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = JSON.parse(localStorage.getItem('users'));

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/user/prasad/getuser/${user.id}`);
        setBookings(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching bookings:', err);
        setError('No Bookings');
        setLoading(false);
      }
    };

    fetchBookings();
  }, [user.id]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="booking-page">
      <h1 className="page-title">Prasad Bookings</h1>
      {bookings.length > 0 ? (
        <div className="booking-list">
          {bookings.map((booking) => (
            <div className="booking-card" key={booking.id}>
              <div className="booking-image">
                <img src={booking.prasadImage} alt={booking.prasad_name} />
              </div>
              <div className="booking-details">
                <h2 className="prasad-name">{booking.prasad_name}</h2>
                <p className="amount">₹{booking.amount}</p>
                <p className="sankalpa-name">
                  <strong>Sankalpa Name:</strong> {booking.sankalpa_name}
                </p>
                <p className="sankalpa-gotra">
                  <strong>Sankalpa Gotra:</strong> {booking.sankalpa_gotra}
                </p>
                <p className="order-date">
                  <strong>Booking Date:</strong>{' '}
                  {new Date(booking.booking_date).toLocaleDateString('en-IN', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No prasad bookings found.</p>
      )}
    </div>
  );
}

export default Prasadbookingpage;

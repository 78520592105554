import React from 'react';
import "../../styles/blogs.css";
import blogimg1 from "../Assets/blog1.jpeg";
import blogimg2 from "../Assets/blog2.jpeg";
import blogimg3 from "../Assets/blog3.jpeg";
import blogimg4 from "../Assets/blog4.jpeg";
import blogimg5 from "../Assets/blog5.jpeg";

function Blogs() {
    return (
        <>
            <div className='blogs_container'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-8'>
                            <h1 className='blog_main_title'>How to Perform a Griha Pravesh Puja: Step-by-Step Guide</h1>
                            <p className='blog_main_para'>Moving into a new house is a significant milestone in life. In Hindu culture, performing a Griha Pravesh Puja is a spiritual and auspicious way to bless the new home and invite positive energy. This comprehensive guide will walk you through the steps to perform a Griha Pravesh Puja, ensuring your new abode is filled with peace, prosperity, and harmony.
                            </p>
                            <div className='blogimg-container'>
                                <img src={blogimg1} alt='blogimg' className='blog_img' />
                            </div>

                            <div class="toc">
                                <h1>Table of Contents</h1>
                                <ol>
                                    <li className='main_dot'><a href="#">What is Griha Pravesh Puja?</a></li>
                                    <li className='main_dot'><a href="#">Significance of Griha Pravesh Puja</a></li>
                                    <li className='main_dot'><a href="#">Types of Griha Pravesh</a>
                                        <ol>
                                            <li className='sub_dot'><a href="#">Apoorva (New House)</a></li>
                                            <li className='sub_dot'><a href="#">Sapoorva (Previously Occupied House)</a></li>
                                            <li className='sub_dot'><a href="#">Dwandwah (After Renovations)</a></li>
                                        </ol>
                                    </li>
                                    <li className='main_dot'><a href="#">Auspicious Dates and Times for Griha Pravesh</a></li>
                                    <li className='main_dot'><a href="#">Auspicious Dates and Times for Griha Pravesh</a></li>
                                    <li className='main_dot'><a href="#">Preparation for Griha Pravesh Puja</a></li>
                                    <li className='main_dot'><a href="#">Step-by-Step Procedure of Griha Pravesh Puja</a>
                                        <ol>
                                            <li className='sub_dot'><a href="#">Cleaning and Purification of the House</a></li>
                                            <li className='sub_dot'><a href="#">Decorate the Entrance and Altar</a></li>
                                            <li className='sub_dot'><a href="#">Kalash Sthapana (Sacred Pot Setup)</a></li>
                                            <li className='sub_dot'><a href="#">Ganesh Puja</a></li>
                                            <li className='sub_dot'><a href="#">Navagraha Shanti Puja</a></li>
                                            <li className='sub_dot'><a href="#">Havan or Homam</a></li>
                                            <li className='sub_dot'><a href="#">Vaastu Puja</a></li>
                                            <li className='sub_dot'><a href="#">Final Rituals and Offerings</a></li>
                                        </ol>
                                    </li>
                                    <li className='main_dot'><a href="#">Items Required for Griha Pravesh Puja</a></li>
                                    <li className='main_dot'><a href="#">Do’s and Don’ts of Griha Pravesh Puja</a></li>
                                    <li className='main_dot'><a href="#">Common FAQs About Griha Pravesh Puja</a></li>
                                    <li className='main_dot'><a href="#">Conclusion</a></li>
                                </ol>
                            </div>

                            <h2 className='blog_sub_title'>1. What is Griha Pravesh Puja?</h2>
                            <p className='blog_main_para'>Griha Pravesh Puja is a Hindu ritual performed before entering a new home for the first time. It is a way to seek blessings from the deities and ensure that the house is free from negative energies. This puja also signifies the beginning of a harmonious and prosperous life in the new house.</p>

                            <img src={blogimg2} alt='blogimg' className='blog_img' />


                            <h2 className='blog_sub_title'>2. Significance of Griha Pravesh Puja</h2>
                            <ul>
                                <li className='sub_title_1'>Removes negative energy and vastu doshas.</li>
                                <li className='sub_title_1'>Invokes blessings from Lord Ganesha, the remover of obstacles.</li>
                                <li className='sub_title_1'>Brings peace, prosperity, and happiness to the household.</li>
                                <li className='sub_title_1'>Creates a spiritual ambiance in the house.</li>
                            </ul>

                            <h2 className='blog_sub_title'>3. Types of Griha Pravesh</h2>
                            <ul>
                                <li className='sub_title_1'><strong>Apoorva (New House):</strong> This type of puja is performed when you are entering a completely new house for the first time.</li>
                                <li className='sub_title_1'><strong>Sapoorva (Previously Occupied House):</strong> If the house was previously occupied by someone else, this puja purifies the space before moving in.</li>
                                <li className='sub_title_1'><strong>Dwandwah (After Renovations):</strong> Performed after major renovations or reconstruction to cleanse the house and invite positive energy.</li>
                            </ul>

                            <h2 className='blog_sub_title'>4. Auspicious Dates and Times for Griha Pravesh</h2>
                            <p className='blog_main_para'>Consult a priest or astrologer to determine the shubh muhurat (auspicious time). Avoid performing the puja during inauspicious periods like Rahu Kaal or Chandra Dosh. Days like Akshaya Tritiya, Vasant Panchami, and Dussehra are considered highly auspicious.</p>

                            <img src={blogimg3} alt='blogimg' className='blog_img' />

                            <h2 className='blog_sub_title'>5. Preparation for Griha Pravesh Puja</h2>
                            <ul>
                                <li className='sub_title_1'>Clean the house thoroughly: Ensure every corner is clean and free from clutter.</li>
                                <li className='sub_title_1'>Decorate the entrance: Use rangoli, flowers, and mango leaves.</li>
                                <li className='sub_title_1'>Prepare the altar: Set up a clean space with idols of deities, especially Lord Ganesha.</li>
                                <li className='sub_title_1'>Gather all puja items: Refer to the checklist in Section 7.</li>
                            </ul>

                            <h2 className='blog_sub_title'>6. Step-by-Step Procedure of Griha Pravesh Puja</h2>

                            <img src={blogimg4} alt='blogimg' className='blog_img' />

                            <h3 className='blog_sub_title'>6.1 Cleaning and Purification of the House</h3>
                            <ul>
                                <li className='sub_title_1'>Mop the floors with Gangajal (holy water) to purify the house.</li>
                                <li className='sub_title_1'>Burn incense or dhoop to cleanse the environment.</li>
                            </ul>

                            <h3 className='blog_sub_title'>6.2 Decorate the Entrance and Altar</h3>
                            <ul>
                                <li className='sub_title_1'>Create a rangoli at the entrance.</li>
                                <li className='sub_title_1'>Tie torans (mango leaves) at the doorway.</li>
                                <li className='sub_title_1'>Place a coconut and kalash (sacred pot) at the entrance.</li>
                            </ul>

                            <h3 className='blog_sub_title'>6.3 Kalash Sthapana (Sacred Pot Setup)</h3>
                            <ul>
                                <li className='sub_title_1'>Fill the kalash with water, mango leaves, and a coconut on top.</li>
                                <li className='sub_title_1'>Place the kalash near the altar.</li>
                            </ul>

                            <h3 className='blog_sub_title'>6.4 Ganesh Puja</h3>
                            <ul>
                                <li className='sub_title_1'>Worship Lord Ganesha with flowers, incense, and sweets.</li>
                                <li className='sub_title_1'>Chant Ganesh mantras to remove obstacles.</li>
                            </ul>

                            <h3 className='blog_sub_title'>6.5 Navagraha Shanti Puja</h3>
                            <ul>
                                <li className='sub_title_1'>Perform puja for the nine planets to nullify any negative planetary influences.</li>
                            </ul>

                            <h3 className='blog_sub_title'>6.6 Havan or Homam</h3>
                            <ul>
                                <li className='sub_title_1'>Light the sacred fire and offer ghee, rice, and samagri (mixture of herbs and grains).</li>
                                <li className='sub_title_1'>Chant Vedic mantras for purification and prosperity.</li>
                            </ul>

                            <h3 className='blog_sub_title'>6.7 Vaastu Puja</h3>
                            <ul>
                                <li className='sub_title_1'>Seek blessings from Vaastu Purush to align the house with positive energies.</li>
                            </ul>

                            <h3 className='blog_sub_title'>6.8 Final Rituals and Offerings</h3>
                            <ul>
                                <li className='sub_title_1'>Distribute prasad (sacred food) among attendees.</li>
                                <li className='sub_title_1'>Walk through the house with a lit diya (lamp) to spread positive energy.</li>
                            </ul>

                            <h2 className='blog_sub_title'>7. Items Required for Griha Pravesh Puja</h2>
                            <ul>
                                <li className='sub_title_1'>Idols or pictures of deities: Lord Ganesha and other gods.</li>
                                <li className='sub_title_1'>Kalash: Copper or silver pot filled with water.</li>
                                <li className='sub_title_1'>Flowers and garlands: For decoration and offering.</li>
                                <li className='sub_title_1'>Mango leaves: For toran and kalash.</li>
                                <li className='sub_title_1'>Coconut: To place on the kalash.</li>
                                <li className='sub_title_1'>Incense sticks and dhoop: For purification.</li>
                                <li className='sub_title_1'>Ghee and camphor: For havan.</li>
                                <li className='sub_title_1'>Havan samagri: Mixture of herbs, grains, and sandalwood.</li>
                                <li className='sub_title_1'>Rangoli colors: For decorating the entrance.</li>
                            </ul>

                            <h2 className='blog_sub_title'>8. Do’s and Don’ts of Griha Pravesh Puja</h2>

                            <h3 className='blog_sub_title'>Do’s</h3>
                            <ul>
                                <li className='sub_title_1'>Consult a priest to perform the rituals properly.</li>
                                <li className='sub_title_1'>Choose an auspicious date and time.</li>
                                <li className='sub_title_1'>Ensure the house is clean and well-decorated.</li>
                                <li className='sub_title_1'>Involve all family members in the puja.</li>
                            </ul>

                            <img src={blogimg5} alt='blogimg' className='blog_img' />

                            <h3 className='blog_sub_title'>Don’ts</h3>
                            <ul>
                                <li className='sub_title_1'>Avoid performing the puja during inauspicious times.</li>
                                <li className='sub_title_1'>Do not use broken items for rituals.</li>
                                <li className='sub_title_1'>Avoid moving into the house before the puja.</li>
                            </ul>

                            <h2 className='blog_sub_title'>9. Common FAQs About Griha Pravesh Puja</h2>
                            <ul>
                                <li className='sub_title_1'><strong>Q: Can Griha Pravesh Puja be performed without a priest?</strong> A: While a priest is recommended for proper rituals, you can perform the puja yourself by following the correct procedures and mantras.</li>
                                <li className='sub_title_1'><strong>Q: Is it necessary to fast on the day of Griha Pravesh Puja?</strong> A: Fasting is not mandatory but is considered auspicious.</li>
                                <li className='sub_title_1'><strong>Q: What should be the first item brought into the new house?</strong> A: A kalash filled with water or milk, symbolizing purity and prosperity.</li>
                                <li className='sub_title_1'><strong>Q: Can we perform Griha Pravesh Puja after sunset?</strong> A: It is ideal to perform the puja during daylight hours as per the shubh muhurat.</li>
                                <li className='sub_title_1'><strong>Q: How long does the Griha Pravesh Puja take?</strong> A: The puja typically takes 2-3 hours, depending on the rituals included.</li>
                            </ul>

                            <h2 className='blog_sub_title'>10. Conclusion</h2>
                            <p className='blog_main_para'>Performing a Griha Pravesh Puja is a deeply spiritual practice that sets the tone for a peaceful and prosperous life in your new home. By following this step-by-step guide, you can ensure that your home is blessed with positive energy and divine grace. Remember to consult a priest for accurate rituals and choose an auspicious time for the puja. May your new home be a place of happiness, harmony, and abundance!</p>
                        </div>

                        <div className='col-sm-4'>
                            <div className='articles'>
                                <h1>Recommended articles</h1>

                                <div className='articles_box'>
                                    <div className='articles_img'>
                                        <img src={blogimg2} alt='blogimg' />
                                    </div>
                                    <div className='articles_content'>
                                        <h2>Griha Pravesh Pooja</h2>
                                        <p>Moving into a new house is a significant milestone in life.</p>
                                    </div>
                                </div>

                                <div className='articles_box'>
                                    <div className='articles_img'>
                                        <img src={blogimg2} alt='blogimg' />
                                    </div>
                                    <div className='articles_content'>
                                        <h2>Griha Pravesh Pooja</h2>
                                        <p>Moving into a new house is a significant milestone in life.</p>
                                    </div>
                                </div>
                                <div className='articles_box'>
                                    <div className='articles_img'>
                                        <img src={blogimg2} alt='blogimg' />
                                    </div>
                                    <div className='articles_content'>
                                        <h2>Griha Pravesh Pooja</h2>
                                        <p>Moving into a new house is a significant milestone in life.</p>
                                    </div>
                                </div>
                                <div className='articles_box'>
                                    <div className='articles_img'>
                                        <img src={blogimg2} alt='blogimg' />
                                    </div>
                                    <div className='articles_content'>
                                        <h2>Griha Pravesh Pooja</h2>
                                        <p>Moving into a new house is a significant milestone in life.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className='related_blogs'>
                <div className='container'>
                    <h1 className='related_blog_main_title'>Related Blogs</h1>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className='blogs_box'>
                                <img src={blogimg1} alt='blog_img' className='related_blog_img' />
                                <h2 className='related_blog_title'>Griha Pravesh Puja</h2>
                                <p className='related_blog_para'>Moving into a new house is a significant milestone in life. In Hindu culture, performing a Griha Pravesh Puja is a spiritual...</p>
                                <button className='related_blog_btn'>Read More</button>
                            </div>
                        </div>

                        <div className='col-sm-4'>
                            <div className='blogs_box'>
                                <img src={blogimg2} alt='blog_img' className='related_blog_img' />
                                <h2 className='related_blog_title'>Griha Pravesh Puja</h2>
                                <p className='related_blog_para'>Moving into a new house is a significant milestone in life. In Hindu culture, performing a Griha Pravesh Puja is a spiritual...</p>
                                <button className='related_blog_btn'>Read More</button>
                            </div>
                        </div>

                        <div className='col-sm-4'>
                            <div className='blogs_box'>
                                <img src={blogimg3} alt='blog_img' className='related_blog_img' />
                                <h2 className='related_blog_title'>Griha Pravesh Puja</h2>
                                <p className='related_blog_para'>Moving into a new house is a significant milestone in life. In Hindu culture, performing a Griha Pravesh Puja is a spiritual...</p>
                                <button className='related_blog_btn'>Read More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Blogs;

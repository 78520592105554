import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../Axios/api';
import "../../styles/booking.css";

function Ecomerceookingpage() {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  const user = JSON.parse(localStorage.getItem("users"));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/orders/getby/${user.id}`);

        const ordersArray = Object.values(response.data.data.orders);
        setCount(response.data.data.orderCount);
        setBookings(ordersArray);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError('Failed to fetch bookings');
        setLoading(false);
      }
    };
    fetchBookings();
  }, [user.id]);

  if (loading) {
    return <div className="loading-container"><p className="loading">Loading...</p></div>;
  }

  if (error) {
    return <div className="error-container"><p className="error">{error}</p></div>;
  }

  const handleNavigate = (orderId, booking) => {
    navigate(`/bookingdetailspage/${orderId}`, {
      state: {
        quantity: booking.quantity,
        orderDate: booking.orderDate
      }
    });
  };

  return (
    <div className="order-page">
      <h1 className="page-title">Your Orders</h1>
      <div className="decorative-line"></div>
      {count > 0 ? (
        <div className="order-list">
          {bookings.map((booking, index) => (
            <div className="order-card" key={index}>
              <div className="order-header">
                <h3 className="order-id">Order ID: {booking.orderId}</h3>
                <p className="order-date">{new Date(booking.orderDate).toLocaleDateString()}</p>
              </div>
              <p className="order-price">Total Price: <span>&#8377;{booking.totalPrice}</span></p>

              <div className="images-container">
                {booking.images.map((image, imgIndex) => (
                  <div
                    key={imgIndex}
                    className="image-wrapper"
                    onClick={() => handleNavigate(booking.orderId, booking)}
                  >
                    <img
                      src={image}
                      alt={`Order ${booking.orderId}`}
                      className="order-image"
                    />
                    <div className="quantity-badge">
                      {booking.quantity[imgIndex]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-orders">No orders found.</p>
      )}
    </div>
  );
}

export default Ecomerceookingpage;

import React from 'react'

function Refundpolicy() {
    const containerStyle = {
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.8",
        color: "#333",
        backgroundColor: "#f9f9f9",
    };

    const sectionStyle = {
        marginBottom: "30px",
        borderBottom: "1px solid #ccc",
        paddingBottom: "20px",
    };

    const headingStyle = {
        color: "#000",
        fontWeight: "bold",
    };

    const subheadingStyle = {
        color: "#000",
        fontSize: "1.2rem",
        margin: "10px 0",
    };

    const noteStyle = {
        fontWeight: "bold",
        color: "#d9534f",
        marginTop: "10px",
    };

    const addressStyle = {
        margin: "10px 0",
        fontStyle: "italic",
    };

    const ulStyle = {
        marginLeft: "20px",
        listStyleType: "disc",
    };

    return (
        <div style={containerStyle}>
            <h1 style={{ ...headingStyle, fontSize: "2rem", textAlign: "center" }}>
                Cancellation, Returns & Replacement Policy
            </h1>

            {/* Return & Replacement Policy Section */}
            <section style={sectionStyle}>
                <h2 style={headingStyle}>10 Days Return & Replacement Policy</h2>
                <p>
                    PrabhuPooja (prabhupooja.com) offers a very transparent and simple
                    no-questions-asked 10 Days Return & Replacement policy for
                    Prabhupooja-related items.
                </p>

                <h3 style={subheadingStyle}>
                    Conditions To Be Eligible For The Returns & Replacement Policy:
                </h3>
                <ul style={ulStyle}>
                    <li>Prabhupooja item(s) must be in their original, unused condition.</li>
                    <li>
                        Replacement for Prabhupooja items is subject to inspection and
                        checking by the Prabhu Pooja team.
                    </li>
                    <li>
                        Damages due to neglect or improper usage will not be included under
                        our Returns & Replacement Policy for Prabhupooja items.
                    </li>
                    <li>
                        Prabhupooja product should be accompanied with the Original Product
                        Certificate and Original/Copy of Invoice, packaging, documentation,
                        lab certificates, discount coupons, etc.
                    </li>
                    <li>
                        Return will not be accepted if there are any modifications done to
                        the original Prabhupooja product(s).
                    </li>
                </ul>
                <p style={noteStyle}>
                    Note: Shipping charges, VAT, Lab certification charges, and custom
                    duties are not refundable for Prabhupooja items.
                </p>
            </section>

            {/* Cancellation Policy Section */}
            <section style={sectionStyle}>
                <h2 style={headingStyle}>Cancellation Policy for Prabhupooja Items</h2>
                <ul style={ulStyle}>
                    <li>
                        To be eligible for cancellation, one must raise the request before
                        the Prabhupooja items have been shipped.
                    </li>
                    <li>
                        If you cancel a Rudraksha or Prabhupooja jewelry order, Rudra Centre
                        may deduct making charges.
                    </li>
                    <li>
                        For customized Prabhupooja items, some amount may be deducted to
                        recover the basic costs of customizations (depends on the stage of
                        making).
                    </li>
                    <li>
                        The refund will be processed within 4-5 working days after you raise
                        the cancellation request.
                    </li>
                    <li>
                        If the cancellation request is raised after the Prabhupooja items
                        have been shipped and are in transit, shipping charges will be
                        deducted from your invoice.
                    </li>
                </ul>
                <p style={noteStyle}>
                    Note: Refunds will be processed back to the original payment method.
                </p>
            </section>

            {/* Return Instructions Section */}
            <section style={sectionStyle}>
                <h2 style={headingStyle}>How To Send The Return Prabhupooja Items?</h2>
                <p>
                    It is highly recommended to allow Prabhu Pooja to manage and arrange
                    for the reverse courier. If you choose to send the parcel yourself and
                    it gets stolen/misplaced/lost in transit, Prabhupooja will not be held
                    liable.
                </p>
                <p>Return charges will be borne by you.</p>

                <h3 style={subheadingStyle}>
                    Instructions For Domestic Returns (Within India Only):
                </h3>
                <ul style={ulStyle}>
                    <li>Pack items securely in a box and, if possible, add padding.</li>
                    <li>Send to:</li>
                    <address style={addressStyle}>
                        Prabhu Pooja<br />
                        203, Mangal City Mall,<br />
                        Near by Vijay Nagar Square,<br />
                        Indore, Madhya Pradesh 452010<br />
                        Telephone: +91 7225016699
                    </address>
                    <li>Use a trusted courier service (best practice is to allow reverse pickup).</li>
                    <li>Declare as: "Gift item. No commercial purpose or value."</li>
                </ul>
            </section>

            {/* FAQs Section */}
            <section style={sectionStyle}>
                <h2 style={headingStyle}>Frequently Asked Questions</h2>
                <h3 style={subheadingStyle}>1. When does the 10-Day Return Period Start?</h3>
                <p>The 10-Day period starts from the day you receive your Prabhupooja product.</p>
                <h3 style={subheadingStyle}>2. Can I return only specific Prabhupooja items?</h3>
                <p>Yes, you can request a refund for only specific items.</p>
            </section>
        </div>
    );
}


export default Refundpolicy;
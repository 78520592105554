import React, { useState } from "react";
import "../../styles/login.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../Axios/api";

const Login = () => {
  const [input, setInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [inputError, setInputError] = useState("");

  const validateInput = () => {
    // Regular expression to validate email and mobile number
    const mobileRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!mobileRegex.test(input) && !emailRegex.test(input)) {
      setInputError(
        "Please enter a valid 10-digit mobile number or email address"
      );
    } else {
      setInputError("");
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateInput();

    // If there's an input validation error, don't proceed with the request
    if (inputError) {
      return;
    }

    try {
      const response = await api.post("/users/login", {
        input: input, // Sending input which can be either mobile or email
      });
      console.log(input);
      console.log("User data:", response.data);
      if (response.data.token) {
        localStorage.setItem("token", JSON.stringify(response.data.token));
        localStorage.setItem("users", JSON.stringify(response.data.user));
        localStorage.setItem("member", JSON.stringify(response.data.member));
        navigate("/otp");
      }
      console.log("Login success:", response.data);
    } catch (error) {
      console.error("Login failed:", error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Login failed. Please try again later.");
      }
    }
  };

  return (
    <div className="addUser">
      <h3>Sign in</h3>
      <form className="addUserForm" onSubmit={handleSubmit}>
        <div className="inputGroup">
          <label htmlFor="input">Mobile Number or Email:</label>
          <input
            type="text"
            id="input"
            autoComplete="off"
            placeholder="Enter your Mobile Number or Email"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onBlur={validateInput}
          />
          {inputError && <p className="error">{inputError}</p>}
          <button type="submit" className="btn btn-primary">
            Verify OTP
          </button>
        </div>
        {errorMessage && <p>{errorMessage}</p>}
      </form>
      <div className="login">
        <p>Don't have an Account? </p>
        <Link to="/signup" className="btn btn-success">
          Sign Up
        </Link>
      </div>
    </div>
  );
};

export default Login;

import React from "react";

function ShippingPolicy() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Shipping Policy</h1>

      <section style={styles.section}>
        <h2 style={styles.subTitle}>Domestic Shipping</h2>
        <p style={styles.text}>
          We use <strong>Blue Dart</strong> and <strong>Speed Post</strong> for
          deliveries in India.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subTitle}>International Shipping</h2>
        <p style={styles.text}>
          We rely on <strong>FEDEX</strong>, <strong>DHL</strong>, and{" "}
          <strong>Speed Post</strong> for international deliveries.
        </p>
        <p style={styles.text}>
          Our courier partners provide live tracking, dedicated support,
          door-to-door delivery, and proof of delivery. We guarantee fast and
          secure shipping. In case of lost parcels, we offer replacements or
          refunds.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subTitle}>Packaging & Transit Damage</h2>
        <p style={styles.text}>
          Our secure packaging minimizes transit damage. If you receive a
          damaged parcel, report it immediately with photos at{" "}
          <a href="mailto:enquiry@prabhupooja.com" style={styles.link}>
            enquiry@prabhupooja.com
          </a>{" "}
          for a replacement.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subTitle}>Additional Information</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>COD is available for domestic orders.</li>
          <li style={styles.listItem}>
            Free shipping on orders above <strong>INR 500</strong>.
          </li>
          <li style={styles.listItem}>
            Shipping charges depend on weight and dimensions.
          </li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subTitle}>Shipping Time</h2>
        <h3 style={styles.smallTitle}>Domestic Orders</h3>
        <p style={styles.text}>
          Blue Dart delivers in <strong>1-2 days</strong>, while Speed Post
          takes up to <strong>5 days</strong>.
        </p>

        <h3 style={styles.smallTitle}>International Orders</h3>
        <p style={styles.text}>
          FEDEX and DHL deliver in <strong>2-4 days</strong>. Speed Post may
          take <strong>7-14 days</strong>. Customs delays may occur.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subTitle}>Custom Duties</h2>
        <p style={styles.text}>
          Custom duties, if applicable, are borne by the recipient. Contact us
          at{" "}
          <a href="mailto:enquiry@prabhupooja.com" style={styles.link}>
            enquiry@prabhupooja.com
          </a>{" "}
          for assistance.
        </p>
      </section>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: "1200px",
    margin: "20px auto",
    padding: "20px",
    lineHeight: "1.6",
    color: "#333",
  },
  title: {
    fontSize: "1.5rem",
    fontweight: "600",
    textAlign: "left",
    marginBottom: "20px",
    color: "#000",
    paddingBottom: "10px",
  },
  subTitle: {
    fontSize: "1.2rem",
    margin: "20px 0 10px",
    color: "#333",
  },
  smallTitle: {
    fontSize: "1rem",
    marginTop: "15px",
    color: "#555",
  },
  text: {
    fontSize: "0.9rem",
    marginBottom: "15px",
  },
  list: {
    listStyleType: "disc",
    margin: "10px 20px",
  },
  listItem: {
    marginBottom: "10px",
  },
  link: {
    color: "#007BFF",
    textDecoration: "underline",
  },
};

export default ShippingPolicy;

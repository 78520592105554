import React from 'react';

function PaymentPolicy() {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#f9f9f9', padding: '20px' }}>
      <div style={{ maxWidth: '1200px', margin: 'auto',  padding: '30px', borderRadius: '10px' }}>
        <h1 style={{ textAlign: 'center', color: '#333', marginBottom: '20px', fontWeight: "bold", fontSize: "20px" }}>Payment Policy</h1>
        <p style={{ lineHeight: '1.8', color: '#555' }}>
          All our transactions are safe, secure, and fraud-proof. We do not save your card details anywhere on the website; these details are entered on the interface of our payment gateway after checkout, which is 100% safe and secure and follows international regulations for transactions.
        </p>

        <h2 style={{ color: '#333', marginTop: '20px' }}>Accepted Payment Methods</h2>
        <ul style={{ lineHeight: '1.8', paddingLeft: '20px', color: '#555' }}>
          <li><strong>Credit Cards:</strong> All Visa, MasterCard, and American Express Credit Cards are accepted.</li>
          <li><strong>Debit Cards (India):</strong> All Visa and Maestro Debit Cards are accepted.</li>
          <li><strong>Net Banking</strong></li>
          <li><strong>Bank Transfer</strong></li>
          <li><strong>UPI</strong></li>
        </ul>

        <h2 style={{ color: '#333', marginTop: '20px' }}>Credit/Debit Cards</h2>
        <p style={{ lineHeight: '1.8', color: '#555' }}>
          For Credit & Debit Cards, one can pay via any one of our trusted and secure payment gateways:
        </p>
        <ul style={{ paddingLeft: '20px', color: '#555' }}>
          <li><strong>Razorpay</strong></li>
        </ul>

        <p style={{ lineHeight: '1.8', color: '#555' }}>
          You can send the payment to our official email ID: <a href="mailto:prabhupooja@prabhupooja.com">prabhupooja@prabhupooja.com</a>. After making the payment, share a screenshot of the receipt and the links/screenshots of the items you require at <a href="mailto:enquiry@prabhupooja.com">enquiry@prabhupooja.com</a>.
        </p>

        <h2 style={{ color: '#333', marginTop: '20px' }}>Net Banking</h2>
        <p style={{ lineHeight: '1.8', color: '#555' }}>
          We support Net Banking through our payment gateways like Razorpay.
        </p>

        <h2 style={{ color: '#333', marginTop: '20px' }}>UPI</h2>
        <p style={{ lineHeight: '1.8', color: '#555' }}>
          We support UPI payments through our payment gateways like Razorpay for Indian Cards.
        </p>

        <h2 style={{ color: '#333', marginTop: '20px' }}>Bank Transfer/Wire Transfer</h2>
        <p style={{ lineHeight: '1.8', color: '#555' }}>
          One can send payment via bank transfer and share the receipt at <a href="mailto:shopping@prabhupooja.com">shopping@prabhupooja.com</a>.
        </p>

        <h3 style={{ color: '#333', marginTop: '20px' }}>Bank Details</h3>

        <h4 style={{ color: '#555' }}>India Account</h4>
        <ul style={{ paddingLeft: '20px', color: '#555' }}>
          <li><strong>Bank Name:</strong> HSBC Bank</li>
          <li><strong>Account Name:</strong></li>
          <li><strong>Account #:</strong> 0051xxxxx</li>
          <li><strong>IFSC Code:</strong> HSBC0xxxx</li>
          <li><strong>Bank Address:</strong></li>
        </ul>

        <h4 style={{ color: '#555' }}>International Account</h4>
        <ul style={{ paddingLeft: '20px', color: '#555' }}>
          <li><strong>Bank Name:</strong> ICICI Bank Limited</li>
          <li><strong>Account Name:</strong></li>
          <li><strong>Account #:</strong> 1975005xxxxx</li>
          <li><strong>IFSC Code:</strong> ICIC0xxxxx</li>
          <li><strong>Swift Code:</strong> ICICINxxxS</li>
          <li><strong>Bank Address:</strong></li>
        </ul>

        <h4 style={{ color: '#555' }}>HDFC Bank</h4>
        <ul style={{ paddingLeft: '20px', color: '#555' }}>
          <li><strong>Bank Name:</strong> HDFC Bank</li>
          <li><strong>Account Name:</strong> Rudra Centre Services Pvt. Ltd.</li>
          <li><strong>Account #:</strong> 5029036288120</li>
          <li><strong>IFSC Code:</strong> HDFC00xxxx</li>
          <li><strong>Swift Code:</strong> HDFCINxxxxx</li>
          <li><strong>Bank Address:</strong></li>
        </ul>

        <p style={{ marginTop: '20px', lineHeight: '1.8', color: '#555' }}>
          See our <a href="/refund&cancle" style={{ color: '#007bff' }}>Return & Refund Policy</a> for more details.
        </p>
      </div>
    </div>
  );
}

export default PaymentPolicy;

import React, { useEffect, useState } from 'react';
import "../../styles/blog.css";
import { Link } from 'react-router-dom';
import api from '../Axios/api';

// Helper function to truncate text
// Helper function to truncate text
function truncateText(text, wordLimit) {
    if (!text) return ''; // Return an empty string if text is undefined or null
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
}


function Blog() {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await api.get('/blog/get');
                const data = response.data;
                console.log(data);
                if (data.success) {
                    setBlogPosts(data.blogs);
                } else {
                    console.error('Failed to fetch blogs:', data.message);
                }
            } catch (error) {
                console.error('Error fetching blogs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    if (loading) {
        return <div className="loading">Loading blogs...</div>;
    }

    return (
        <>
            <div className="sub_header_blog">
                <div className="container">
                    <div className="subheader_inner_blog">
                        <div className="subheader_text_blog">
                            <h1>Spiritual - Blogs</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link className="btn-link" to="/">
                                        Home
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">Blogs</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="blog-container">
                {/* <header className="blog-header1">
                    <h1 className="blog-title1">Spiritual Blogs</h1>
                    <p className="blog-subtitle1">Your guide to spirituality and rituals</p>
                </header> */}

                <div className="blog-layout">
                    {blogPosts.map((post) => {
                        // Check if images is a string or array, and handle accordingly
                        let firstImage = '';
                        if (post.images) {
                            if (typeof post.images === 'string') {
                                firstImage = post.images;
                            } else if (Array.isArray(post.images)) {
                                firstImage = post.images.length > 0 ? post.images[0] : '';
                            }
                        }

                        return (
                            <div className="post-card" key={post.id}>
                                <Link to="/blogss">
                                    <div
                                        className="post-image"
                                        style={{
                                            backgroundImage: `url(${firstImage || 'default-image-url.jpg'})`,
                                        }}
                                    ></div>
                                </Link>
                                <div className="post-content">
                                    <h2 className="post-title">{post.title || 'Untitled Blog'}</h2>
                                    <p className="post-description">
                                        {truncateText(post.description, 50)}
                                    </p>
                                    <Link to={`/blog/${post.title}`} className="read-more1">
                                        <span>Read More</span>
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default Blog;

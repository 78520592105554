import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import api from '../Axios/api';
import "../../styles/bookingdetailspage.css";

function Bookingdetailspage() {
    const { id } = useParams();
    const location = useLocation();
    const { quantity, orderDate } = location.state || {};
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                setLoading(true);
                const response = await api.get(`/orders/getbyuser/${id}`);
                setProducts(response.data.products);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError('Failed to fetch bookings');
                setLoading(false);
            }
        };
        fetchBookings();
    }, [id]);

    if (loading) {
        return <div className="loading-container"><p className="loading">Loading...</p></div>;
    }

    if (error) {
        return <div className="error-container"><p className="error">{error}</p></div>;
    }

    if (!products.length) {
        return <div className="no-products">No products found for this order.</div>;
    }

    // Calculate total price dynamically
    const totalPrice = products.reduce(
        (sum, product, index) => sum + product[0].productOfferPrice * (quantity[index] || 0),
        0
    );

    return (
        <div className="order-detail-page">
            <h1 className="page-title">Order Details</h1>
            <div className="decorative-line"></div>

            <div className="order-content">
                {/* Product List */}
                <div className="product-list-container">
                    <div className="product-list">
                        {products.map((product, index) => (
                            <div key={product[0].productId} className="product-card">
                                <img
                                    src={product[0].productImage}
                                    alt={product[0].productName}
                                    className="product-image"
                                />
                                <div className="product-info">
                                    <h2 className="product-name">{product[0].productName}</h2>
                                    <p className="product-price">
                                        Price: <span>&#8377;{product[0].productOfferPrice}</span>
                                    </p>
                                    <p className="product-quantity">
                                        Quantity: <span>{quantity[index] || 0}</span>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Bill Summary */}
                <div className="bill-container">
                    <div className="bill-summary">
                        <h2>Bill Summary</h2>
                        <p>Order Date: {new Date(orderDate).toLocaleDateString()}</p>
                        <p>Products:</p>
                        <ul>
                            {products.map((product, index) => (
                                <li key={product[0].productId}>
                                    <span>{product[0].productName}</span>
                                    <span>
                                        &#8377;{product[0].productOfferPrice} x {quantity[index] || 0} =
                                        <strong> &#8377;{product[0].productOfferPrice * (quantity[index] || 0)}</strong>
                                    </span>
                                </li>
                            ))}
                        </ul>
                        <p className="total-highlight">
                            Total Quantity: <span>{quantity.reduce((sum, qty) => sum + qty, 0)}</span>
                        </p>
                        <p className="total-highlight">
                            Total Price: <span>&#8377;{totalPrice.toFixed(2)}</span>
                        </p>
                        <div className="bill-footer">
                            Thank you for shopping with us!
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Bookingdetailspage;

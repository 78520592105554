import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/prasaddelivery.css";
import api from "../Axios/api"; // Assuming this is your axios instance

const Prasaddelivery = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get("/user/prasad/get");

        if (response.data.success) {
          setServices(response.data.data); // Set the fetched prasad data to state
        } else {
          setError(response.data.message || "Failed to fetch services.");
        }
      } catch (error) {
        setError("Error fetching services. Please try again.");
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <div className="sub_header_delivery">
        <div className="container">
          <div className="subheader_inner_delivery">
            <div className="subheader_text_delivery">
              <h1>Prasad Delivery</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Prasad Delivery</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row" style={{ marginTop: "50px" }}>
            {services.map((service) => (
              <div key={service.id} className="col-sm-3">
                <div className="tp-box-prasad">
                  <div className="tp-img">
                    <Link
                      to={`/prasad/${service.id}`}
                      state={{ price: service.price, id: service.id }}
                    >
                      <img
                        src={service.image} // Update image source as per your backend structure
                        alt={service.prasad_name}
                      />
                    </Link>
                  </div>

                  <div className="tp-box-content">
                    <h6>
                      {service.prasad_name} {service.temple_name}
                    </h6>
                    <div className="price-booknow">
                      <div className="price-grp">
                        <p className="current_price">
                          <strong>
                            <span>Rs.{service.price}</span>
                          </strong>
                        </p>
                      </div>
                      <Link
                        className="booknow-btn"
                        to={`/prasad/${service.id}`}
                        state={{ price: service.price, id: service.id }}
                      >
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Prasaddelivery;
